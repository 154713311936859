<template>
	<div>
		<el-table :data="tableData" border class="table el-table" ref="multipleTable"
			header-cell-class-name="table-header" :header-cell-style="{ 'text-align': 'center' }"
			@selection-change="handleSelectionChange" :row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
			:row-class-name="tableRowClassName">
			<el-table-column prop="title" width="200" label="标题" align="center"></el-table-column>
			<el-table-column prop="url" label="图片" align="center">
				<template #default="scope">
					<el-image v-if="scope.row.url" @click="bigImg(scope.row.url)"
						style="width: 50px; height: 50px; margin-bottom:-4px" :src="scope.row.url"
						:preview-src-list="srcList"></el-image>
					<span v-else></span>
				</template>
			</el-table-column>
			<el-table-column prop="createdAt" width="200" label="发布时间" align="center"></el-table-column>

			<el-table-column label="操作" width="180" align="center">
				<template #default="scope">
					<el-button size="small" type="text" icon="el-icon-delete" class="red"
						@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					<el-button size="small" type="text" @click="handleEdit(scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog title="添加公告" v-model="addVisible" width="800px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="公告标题" prop="title"><el-input size="small"
						v-model="form.title"></el-input></el-form-item>
				<el-form-item label="公告内容" prop="content"><el-input size="small"
						v-model="form.content"></el-input></el-form-item>

				<el-form-item label="公告图片" prop="url">
					<el-input size="small" v-model="form.url" v-if="false"></el-input>
					<el-upload :action="domain" :limit="1" list-type="picture-card" :file-list="fileList"
						:auto-upload="false" ref="upload" accept="image/png,image/jpg,image/jpeg"
						:before-upload="beforeAvatarUpload" :on-change="getbusinessLicense" :data="token1"
						:http-request="httprequest" :class="{ hide: hideUpload }" :before-remove="beformRemove"
						:on-remove="handleRemove">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
			</el-form>
			<strong></strong>
			<span class="dialog-footer myRight">
				<el-button size="small" @click="closeDialog('form')">取消</el-button>
				<el-button size="small" :loading="loading" type="primary" @click="addNotice('form')">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import { add, notice, delNotice } from '../api/notice';
	export default {
		data() {
			return {
				tableData: [],
				addVisible: false,
				limitCount: 1,
				form: {
					title: '',
					content: '',
					url: ''
				},
				domain: '',
				srcList: [],
			};
		},

		created() {
			this.getNoticeList();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getNoticeList() {
				notice().then(res => {
					this.tableData = res.data;
				});
			},

			showForm() {
				this.addVisible = true;
			},
			getbusinessLicense(file, fileList) {
				this.hideUpload1 = fileList.length >= this.limitCount;

			},
			beformRemove(fileList) {
				this.hideUpload = fileList.length >= this.limitCount;
			},
			addNotice() {
				add(this.form).then(res => {
					if (res.code == 200) {
						this.$message.success('公告添加成功');
						this.addVisible = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			bigImg(url) {
				this.srcList[0] = url;
			},
			// 点击取消
			closeDialog(formName) {
				this.$refs.upload.clearFiles();
				this.hideUpload = false;
				(this.form = {
					title: '',
					content: '',
					url: ''
				}),
				this.resetForm(formName);
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 删除照片
			handleRemove() {
				this.form.url = '';
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						delNotice(id).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			handleEdit(row) {
				this.$router.push('addNotice');
				localStorage.setItem('rowNotice', JSON.stringify(row));
			},
		}
	};
</script>
<style>
	.red {
		color: #f56c6c;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}
</style>